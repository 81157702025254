/**
 * Farmalytics Main Library
 * Author: Brandon Corbin <brandon@icorbin.com>
 * Team: Jarvis
 * Date: Nov 8 2021
 */
import { API_ENDPOINT, EXPIRE_SOURCE_MS } from "./CONFIG";
import { isGA3, isGA4, getClientId, getUTMParams, } from "./modules/ga/ga";
import toHash from "./modules/hash/hash";
import hyperstorage from "./modules/hyperstorage/hyperstorage";
import { ReferrerToSource } from "./modules/referrer-to-source/referrer-to-source";
/**
 * Generates a unique ID from a Source
 * @param source
 * @returns
 */
const getSourceHash = (source) => {
    return toHash(`${source.campaign}${source.medium}${source.source}`);
};
/**
 * Farmalytics Initializer
 * Setups Farmatics
 */
const FarmalyticsInitializer = () => {
    /**
     * Farmalytics State
     * Holds sources, property info and client id
     */
    const state = {
        sources: [],
        propertyId: null,
        clientId: null,
        shouldTrack: false,
    };
    /**
     * Initialize Farmalytics
     * @param config
     * @returns Promise void
     */
    const init = async (config) => {
        // Set Property Id (needed for GA4 clientId Lookup)
        state.propertyId = config.propertyId;
        // Get Known Sources from User, filter as needed
        state.sources = (hyperstorage.getItem("sources") || []).filter((source) => {
            // If today is less than the createdDate + Expires_SOURCE_MS,
            // it's OK, otherwise it's stale and should be removed
            const expiresOn = new Date(source.created).getTime() + EXPIRE_SOURCE_MS;
            if (new Date().getTime() < expiresOn) {
                return true;
            }
            return false;
        });
        /**
         * Handle Active Srouce
         * If a source is detected (any utm_* param)
         * it will be saved to the users hyperstorage
         */
        let activeSource = getUTMParams();
        /**
         * If we have no sources for this user, lets check the referrer
         * For any knowns sources
         */
        if (state.sources.length === 0 && !activeSource) {
            activeSource = ReferrerToSource();
        }
        if (activeSource) {
            // We have an active Source
            if (config.debug)
                console.log(`🔥 Active Source`, activeSource);
            // Does this source hash already exist?
            const activeHash = getSourceHash(activeSource);
            const exists = state.sources.find((s) => getSourceHash(s) === activeHash);
            // If a source doesn't exxist we'll add it
            if (!exists) {
                // This is a new Source for this user
                state.sources.unshift(activeSource); // Add source to first position
                state.sources = state.sources.filter((s, index) => index < 6); // Limit number of results to 5
                // Save to hyperstorage
                hyperstorage.setItem("sources", state.sources);
                // Track when we're loaded since it's a new
                state.shouldTrack = true;
                if (config.debug)
                    console.log("📁 Saved new source", activeSource);
            }
        }
        // Return a promise since GA might not be fully loaded yet.
        return new Promise(async (resolve) => {
            // Wait till we can get Client ID from Google Analytics
            state.clientId = (await getClientId(state.propertyId)) || "Blocked";
            // Log out if logging
            if (config.debug)
                console.log(`🐐 Property: ${state.propertyId}, Client: ${state.clientId}, GA: ${isGA3() ? "V3" : isGA4() ? "V4" : "⚠️ Unknown"}`);
            // Return promise
            if (state.shouldTrack) {
                setTimeout(() => {
                    track()
                        .then((res) => {
                        if (config.debug)
                            console.log(res);
                    })
                        .catch((e) => {
                        console.error(e);
                    });
                    state.shouldTrack = false;
                }, 500);
            }
            resolve();
        });
    };
    /**
     * Get Property Id
     * Returns the Users Google Analytics Client ID number
     * @returns string | undefined
     */
    const getPropertyId = () => {
        return state.propertyId;
    };
    /**
     * Get Sources
     * Returns an Array of the users last 5 sources
     * @returns Array Sources
     */
    const getSources = () => {
        return state.sources;
    };
    /**
     * Get Source
     * Returns the users last known source
     * @returns UTM Source
     */
    const getSource = () => {
        return state.sources.length ? state.sources[0] : undefined;
    };
    /**
     * Get the URL for the API endpoint to hit
     * @returns URL one level above executing script
     */
    const getUrl = () => {
        try {
            if ((document === null || document === void 0 ? void 0 : document.currentScript) && typeof document.currentScript !== 'undefined') {
                const url = new URL(`${document.currentScript.src}/..`);
                return url.href;
            }
            return API_ENDPOINT;
        }
        catch (e) {
            return API_ENDPOINT;
        }
    };
    const originUrl = getUrl();
    /**
     * Track Source to API
     */
    const track = async () => {
        const source = getSource();
        const push = await fetch((Farmalytics === null || Farmalytics === void 0 ? void 0 : Farmalytics.originUrl) || API_ENDPOINT, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                clientId: state.clientId,
                referrer: document.referrer,
                propertyId: state.propertyId,
                source: (source || {}).source || "na",
                campaign: (source || {}).campaign || "na",
                medium: (source || {}).medium || "na",
                host: window.location.origin,
            }),
            mode: 'cors'
        });
        return await push.json();
    };
    // Return functions
    return {
        init,
        getClientId,
        getPropertyId,
        getSources,
        getSource,
        track,
        originUrl
    };
};
const Farmalytics = FarmalyticsInitializer();
if (window)
    window["Farmalytics"] = Farmalytics;
export default Farmalytics;
