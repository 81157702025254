// Simple Hashing Algo
// No need to worry about collisions above 10,000
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/52171480
const toHash = (obj) => {
    let hash = 0;
    const keyString = JSON.stringify(obj);
    for (let charIndex = 0; charIndex < keyString.length; ++charIndex) {
        hash += keyString.charCodeAt(charIndex);
        hash += hash << 10;
        hash ^= hash >> 6;
    }
    hash += hash << 3;
    hash ^= hash >> 11;
    return (((hash + (hash << 15)) & 4294967295) >>> 0).toString(16);
};
export default toHash;
