import { ALLOWED_PARAMS } from "../../CONFIG";
/**
 * Is this Google Analytics 3
 * @returns boolean
 */
export const isGA3 = () => {
    return window["ga"] ? true : false;
};
/**
 * Is this Google Analytics 4
 * @returns boolean
 */
export const isGA4 = () => {
    return window["gtag"] ? true : false;
};
/**
 * Checks to see when Google V3 is Loaded
 * @returns void
 */
const isV3Loaded = () => {
    return Promise.race([
        new Promise((resolve) => setTimeout(() => resolve(true), 10000)),
        new Promise((resolve) => {
            const isLoaded = () => window["ga"] && window["ga"].loaded
                ? resolve(true)
                : setTimeout(isLoaded, 200);
            isLoaded();
        }),
    ]);
};
/**
 * Get a ClientId for Version 3
 * @returns Promise<string|undefined>
 */
const getGA3ClientId = async () => {
    await isV3Loaded();
    const _ga = window["ga"];
    const ids = _ga === null || _ga === void 0 ? void 0 : _ga.getAll().map((tracker) => {
        return tracker.get("clientId");
    });
    return ids.length ? ids[0] : undefined;
};
/**
 * Get a ClientId for Version 4
 * @returns Promise<string|undefined>
 */
const getGA4ClientId = (propertyId) => {
    return new Promise((resolve) => {
        const _gtag = window["gtag"];
        _gtag("get", propertyId, "client_id", (clientId) => {
            resolve(clientId);
        });
    });
};
/**
 * Get a Client ID
 * @param propertyId
 * @returns
 */
export const getClientId = async (propertyId) => {
    if (isGA3()) {
        return getGA3ClientId();
    }
    else if (isGA4()) {
        return getGA4ClientId(propertyId);
    }
    else {
        return undefined;
    }
};
/**
 * Get the Active Source
 * Parses the url params for any utm_params as listed below
 * @returns UTM or Null
 */
export const getUTMParams = () => {
    const utm = {};
    const params = new URLSearchParams(window === null || window === void 0 ? void 0 : window.location.search);
    ALLOWED_PARAMS.forEach((tag) => {
        const utmlessTag = tag.replace("utm_", "");
        const value = params.get(tag);
        if (value)
            utm[utmlessTag] = value.toString().substr(0, 100); // if it exists capture it in the utm object
    });
    if (Object.keys(utm).length) {
        // Lets timestamp it
        utm.created = new Date();
        utm.source = utm.source || "Google UTM";
        return utm;
    }
    else {
        return undefined;
    }
};
