import { WEBSITE_SOURCES } from "../../CONFIG";
/**
 * Converts a Referrer URL to a SourceType
 * See the WEBSITE_SOURCES for the patterns to identify
 * each of the known urls
 * @param url
 * @returns
 */
export const urlToSource = (url) => {
    // Get array of keys from our source map (all known sources we track)
    const sourceKeys = Object.keys(WEBSITE_SOURCES);
    // Match to the first source key
    const match = sourceKeys.find((searchKey) => {
        return url.search(new RegExp(searchKey, 'gi')) > -1;
    });
    // Return accordingly
    if (match) {
        const source = WEBSITE_SOURCES[match];
        // Set now as the created date
        source.created = new Date();
        return source;
    }
    return undefined;
};
/**
 * Referrer to Source
 * Converts a known referral URL to a source Type
 * @param url
 * @returns
 */
export const ReferrerToSource = (url) => {
    // Get the document - for jest testing 
    const document = (typeof window !== "undefined") ? window.document : undefined;
    // If url is provided, use that as our referral
    // other wise look at document.referrer 
    const referrer = url || (document === null || document === void 0 ? void 0 : document.referrer);
    // Referrer empty is a string --- ""
    if (referrer == "" || !referrer)
        return undefined;
    // Parse to Source 
    return urlToSource(referrer);
};
