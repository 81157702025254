// API end point for tracking source
export const API_ENDPOINT = "https://farmalytics.infarmbureau.com";
// How long should a source last in Days
export const EXPIRE_SOURCE_DAYS = 30;
// How long should a source last for in MS
export const EXPIRE_SOURCE_MS = EXPIRE_SOURCE_DAYS * 24 * 60 * 60 * 1000;
// Which URL Params should we capture and process
export const ALLOWED_PARAMS = [
    "utm_source",
    "utm_content",
    "utm_campaign",
    "utm_medium",
    "utm_id",
    "utm_term",
];
// Known Sources - so we can tag them appropriately
export const WEBSITE_SOURCES = {
    "(facebook).*(ad_id)": {
        source: "Facebook",
        medium: "social-paid",
    },
    facebook: {
        source: "Facebook",
        medium: "social",
    },
    tiktok: {
        source: "TikTok",
        medium: "social",
    },
    twitter: {
        source: "Twitter",
        medium: "social",
    },
    linkedin: {
        source: "LinkedIn",
        medium: "social",
    },
    youtube: {
        source: "Youtube",
        medium: "social",
    },
    gmail: {
        source: "Gmail",
        medium: "email",
    },
    google: {
        source: "Google",
        medium: "organic",
    },
    wallethub: {
        source: "WalletHub",
        medium: "social",
    },
    yelp: {
        source: "Yelp",
        medium: "social",
    },
    clearsurance: {
        source: "Clearsurance",
        medium: "social",
    },
};
