const STORE_KEY = "FAV2";
// A fast way to pull structured 
// data from LocalStorage 
const getFromLocalStorage = () => {
    let data = {};
    try {
        data = JSON.parse((localStorage).getItem(STORE_KEY) || "{}");
    }
    catch (e) {
        console.error(e.message);
    }
    return data || {};
};
/**
 * HyperStorage
 * An object friendly version of LocalStorage using a single data store.
 * @returns hyperStorage
 */
const HyperstorageInit = () => {
    // Get Current Data from LS
    let data = getFromLocalStorage();
    /**
     * Save
     * Save data to Local Storage
     */
    const save = () => {
        localStorage.setItem(STORE_KEY, JSON.stringify(data));
    };
    return {
        getItem(key) {
            return data[key];
        },
        update(newData = {}) {
            data = Object.assign(Object.assign({}, data), newData);
            save();
        },
        setItem(key, value) {
            data[key] = value;
            save();
        },
        deleteItem(key) {
            delete data[key];
            save();
        },
        clear() {
            data = {};
            save();
        },
    };
};
export default HyperstorageInit();
